.table-adjuster{
    .p-datatable .p-datatable-header {
        display: flex;
        justify-content: flex-end;
       
    }
}

// .tab-adjuster{
//     display: flex;
//     align-items: center;
//     width: 83vw;
//     justify-content: space-between;
//     .p-input-icon-left{
//         display: flex;
//     }
// }
// // .table-sel{
//     .p-datatable-header{
//         display: flex;
    
//     }
// }
.tab-header-adjuster-lecturer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.p-datatable-header{
    flex-direction: column;
}

.width-adjuster-for-add-cycleItem-form{
    width: 100%;
}
.font-for-register{
    font-size: 2.7rem;
}
.submit-btn{
    
     float:right;
      width: 7rem !important;
      margin-right: 50px;
}
.cancel-btn{
    
    float:right;
     width: 7rem !important;
     margin-right: 5px;
     background-color: #EF4444 !important;
}
.edit-delete-btn{
    display: flex;
    flex-direction: row;
    margin-left: -15px;
}

.red-color-btn{
    background-color: #ef6464 !important;
    border: 1px solid #ef6464 !important;
}
.p-button-spacing{
    border-radius: 50%;
    height: 3rem;
    margin-left: 5px;
}
// .asd{
// .p-multiselect-panel {
//     z-index: 1002;
//     padding-right: 0px;
//     min-width: 6px;
//     transform-origin: center top;
//     top: 257.75px !important;
//     left: 405.016px !important;
//     width: 15rem !important;
// }
// }

// .width-multiselect{
//     width: 15rem !important;
// }