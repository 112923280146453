h1 {
  font-size: 2.5rem;
}

.table-adjuster {
  .p-datatable .p-datatable-header {
    display: flex;
    justify-content: flex-end;
  }
}
.width-adjuster-for-add-deservant-form {
  width: 100%;
}

.tab-header-adjuster-deservant {
  display: flex;
  align-items: center;
  // width: 83vw;
  justify-content: space-between;
  .p-input-icon-left {
    display: flex;
  }
}
.table-sel {
  .p-datatable-header {
    display: flex;
  }
}
.p-datatable-header {
  flex-direction: column;
}
.color-for-str {
  color: #ff0000;
  font-weight: 700;
  margin-left: 3px;
}

.edit-delete-btn {
  display: flex;
  flex-direction: row;
  margin-left: -15px;
}

.red-color-btn {
  background-color: #ef6464 !important;
  border: 1px solid #ef6464 !important;
}
.p-button-spacing {
  border-radius: 50%;
  height: 3rem;
  margin-left: 5px;
}

.traininghover:hover {
  cursor: pointer;
  background-color: lightblue;
  // text-shadow: 2px 2px 5px black;
  // box-shadow: 10px 10px 5px 12px lightblue;
}
.myDIV {
    height:300px;
    background-color:#FFFFFF;
    column-count:3;
    column-rule: 4px solid black;
  }