.main-body {
  margin: 0px 230px 0px 230px;
  padding-bottom: 50px;
}

.intro {
  text-align: center;
}

.email {
  width: 60%;
}

@media only screen and (max-width: 1360px) {
  .main-body {
    margin: 0px 20px 0px 20px;
  }

  .email {
    width: 100%;
  }
}
