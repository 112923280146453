/* login.css */

.flexgrid-demo .box {
  /* background-color: var(--surface-e); */
  text-align: center;
  padding-top: 3rem;
  text-align: -webkit-center;
}
.custom-password-field-new{
  width: 70% !important;

}
.custom-password-field input {
  width: inherit !important;
}

.custom-button {
  width: 25% !important;
  float: right;
}

.custom-text-align {
  text-align: initial;
}

@media screen and (min-width: 992px) {
  .lg\:w-6 {
    width: 35% !important;
  }
}

@media screen and (min-width: 992px) {
  .surface-card.p-4.shadow-2.border-round.w-full.lg\:w-6 {
    width: 30% !important;
  }
}

.custom-button-color .p-button {
  background: #04c8eb !important;
  border-color: #04c8eb !important;
}
.btnNsignup{
  display: flex;
    align-items: center;
    justify-content: end;
}
.noAc{
  font-size: 1.1rem;
  font-weight: 600;
  color: black;
  
}
.singup{
 color: #04c8eb;
}
.singup:hover{
  text-decoration: underline !important;
  cursor: pointer;
}
.form-align-justify{
  display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 95%;
}
.custom-width-sp{
  width: 70% !important;
}
.choiceMainCon{
  display: flex;
  justify-content: space-around;
}
.cCard{
  margin-top: 10px;
  padding: 10% 15%;
/* border: 1px solid black; */
border-radius: 10px;
box-shadow: 0px 0px 8px 1px rgba(74,74,74,0.75);
-webkit-box-shadow: 0px 0px 8px 1px rgba(74,74,74,0.75);
-moz-box-shadow: 0px 0px 8px 1px rgba(74,74,74,0.75);
}
.cText{
  /* border: 1px solid red; */
  padding: 10px 0px;
  color: black;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}
.cCard:hover{
  cursor: pointer;
}
.conLabels{
  width: 28%;
}