.width-adjuster-for-add-cycle-form {
  width: 100%;
}
.field {
  margin-bottom: 2.5rem;
}
.font-for-cycle {
  font-size: 3rem;
}
.submit-btn {
  float: right;
  width: 9rem !important;
  margin-right: 50px;
}
.cancel-btn {
  float: right;
  width: 7rem !important;
  margin-right: 5px;
  background-color: #ef4444 !important;
}
.mytab2 {
  min-height: 64vh;
  max-height: 64vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
