.table-adjuster{
    .p-datatable .p-datatable-header {
        display: flex;
        justify-content: flex-end;
    }
}

.tab-header-adjuster-item{
    display: flex;
    align-items: center;
    // width: 83vw;
    justify-content: space-between;
    .p-input-icon-left{
        display: flex;
    }
}
.table-sel{
    .p-datatable-header{
        display: flex;
    
    }
}
.delete-button{
    border: none;
    background-color: #EF4444  !important;
}
.edit-delete-btn{
    display: flex;
    flex-direction: row;
    margin-left: -15px;
}

.red-color-btn{
    background-color: #ef6464 !important;
    border: 1px solid #ef6464 !important;
}
.p-button-spacing{
    border-radius: 50%;
    height: 3rem;
    margin-left: 5px;
}