.width-adjuster-for-add-item-form{
    width: 100%;
}
.font-for-register{
    font-size: 2.7rem;
}
.submit-btn{
    
     float:right;
      width: 7rem !important;
      margin-right: 50px;
}
.cancel-btn{
    
    float:right;
     width: 7rem !important;
     margin-right: 5px;
     background-color: #EF4444 !important;
    }