.carding {
  .p-card-body {
    padding-bottom: 0.5rem !important;
  }
  .p-card-content {
    padding: 0px !important;
  }
  .heading-selector {
    margin-bottom: 10px;
  }

  // border-radius: 6px 6px 0px 0px !important;
  .color-adjuster {
    // padding: 6px 13px;
    background-color: #00b1ff;
    color: white;
    // border-radius: 16px;
  }
  .width-adjuster {
    display: flex;
    justify-content: space-evenly;
    // width: 62vw;
    flex-wrap: wrap;
  }
}

.selector-identifier {
  .p-tabview-nav {
    justify-content: center !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-tabview-ink-bar {
    background-color: #00b1ff !important;
  }
  .p-tabview {
    border-color: #00b1ff !important;
  }
  .p-tabview-nav-link {
    color: #00b1ff !important;
    // border-color: #00b1ff !important;
  }
}
/* border: 1px solid #00b1ff; */
/* border: 1px solid #00b1ff; */

//   .p-tabview-nav li.p-highlight .p-tabview-nav-link
.summaryCon {
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid #dee2e6;
  justify-content: space-between;
  margin-top: 10px;
}
.cycle-summary-view {
  min-height: 71vh;
  max-height: 71vh;
}
.valumarginlizer {
  margin-bottom: 0em !important;
  font-size: 1.2rem;
}
.padd-adjuster-for-summary {
  .p-card-body {
    padding: 0rem 1.25rem !important;
  }
  .p-card-content {
    padding: 1.45rem 0 !important;
  }
}
