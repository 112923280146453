.cyclehover:hover {
  cursor: pointer;
}

.Button {
  text-align: justify;
  align-items: center;
  align-content: center;
  padding: 0%;
  border: 50%;
}

.tab-header-adjuster-cycle {
  display: flex;
  align-items: center;
  // width: 73vw;
  justify-content: space-between;
  .p-input-icon-left {
    display: flex;
  }
}
.table-sel {
  .p-datatable-header {
    display: flex;
  }
}
.overlaypanel-demo .select-product-button {
  min-width: 15rem;
  .overlaypanel-demo {
    padding: 0.5rem;
  }
  // box-sizing: content-box;
}
.overlaypanel-demo {
  .select-product-button {
    box-sizing: content-box;
  }
}

.p-datatable-column-header {
  width: 5%;
  text-align: center;
}
.myTable td:nth-child(1) {
  width: 90rem !important;
  border: 1px solid red !important; 
}

.cyclehover-img{
  cursor: pointer;
  width: 10rem;
  flex-wrap: wrap;
   flex-direction: column;
}

