.pending-user-card {
  width: 344px;
  height: 236px;
  border: none;
  /* Remove the border */
  border-radius: 20px;
  box-shadow: 0px 0px 16px 0px #00000059;
}

.card1 {
  width: 344px;
  height: 55px;
  background-color: #29abe2;
  color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 8px;
  margin-bottom: 8px;
}

.card-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.info-icon {
  color: #1890ff;
  cursor: pointer;
}

.user-details {
  display: flex;
  align-items: center;
  margin-top: -19px;
}

.avatar {
  width: 69px;
  height: 69px;
  border-radius: 0;
  opacity: 1; // Visibility is now 100%
}

.user-name {
  width: 219px;
  height: 24px;
  margin-left: 3px; // Small margin for spacing from the image
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  size: 16px;
  line-height: 3px;
  text-align: left;
  padding: 4px; // Adjust padding for better visual appearance
  border-radius: 4px; // Optional: To round corners of background
  opacity: 1; // Full visibility
}

.referred-by {
  width: 219px;
  height: 21px;
  margin-left: 56px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 0px;
  text-align: left;
  opacity: 1; // Full visibility
  margin-top: -27px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.accept-button {
  width: 153px;
  height: 40px;
  gap: 0px;
  border-radius: 25px 25px 25px 25px;
  opacity: 0px;
  pointer-events: auto;
  background-color: #29abe2 !important;
  // box-shadow: 0px 0px 12px 0px #00000040;
}
.accept-button:hover {
  box-shadow: 0px 0px 12px 0px #00000040;

  cursor: pointer; /* Optional: Change cursor on hover */
}

.decline-button {
  width: 116px;
  height: 40px;
  gap: 0px;
  border-radius: 25px;
  background-color: #e8e8e8; /* Default background */
  color: #949494; /* Default text color */

  opacity: 1;
  pointer-events: auto;
}

.decline-button:hover {
  box-shadow: 0px 0px 12px 0px #00000040;
  background-color: #e8e8e8; /* Keep the same background color */
  color: #727272 !important; /* Change text color on hover */
  cursor: pointer; /* Optional: shows a pointer cursor on hover */
}
/* Set font color */

.card-title {
  display: flex;
  align-items: center;
  color: white;

  .clock-icon {
    margin-right: 8px;
  }

  .requested-date {
    color: white;
  }
}

.ant-card .ant-card-head {
  border: none;
}

.ant-card .ant-card-body {
  padding-top: 15px;
}

// model

.custom-modal .ant-modal-content {
  border-radius: 10px;
  padding: 20px;
  // width: 00px; /* Adjust popup width */
  height: 600px;
  font-family: Arial, sans-serif;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  position: relative;
}

.modal-header .ant-avatar {
  border-radius: 50%;
  border: 2px solid #e0e0e0;
}

.top-right-info {
  display: flex;
  align-items: center;
  color: #5c5c5c;
  font-weight: bold;
  font-size: 14px;
}

.clock-icon {
  margin-right: 5px;
  font-size: 18px;
}

.requested-date {
  font-size: 14px;
  color: #5c5c5c;
}

.modal-content {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.left-column,
.right-column {
  width: 50%;
}

.left-column {
  padding-right: 30px;
  border-right: 1px solid #b0b0b0;
  margin: 25px;
}

.right-column {
  padding-left: 25px;
}

.line-to-format {
  display: flex; /* Arrange elements horizontally */
  justify-content: space-between; /* Distribute elements evenly */
  align-items: center; /* Align elements vertically */
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  padding: 10px 0; /* Add padding for spacing */
}

.line-to-format label {
  font-weight: bold; /* Make labels bold */
}

.line-to-format input {
  width: 200px; /* Adjust input width as needed */
  padding: 5px;
  border: 1px solid #ccc;
}

strong {
  font-weight: bold;
  color: #000;
}

.ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: left;
}

/* Ensure avatar size and positioning */
.ant-avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
}

.modal-header .ant-avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .pending-user-card {
    width: 95%; /* Shrink card width for smaller screens */
    height: auto; /* Adjust height to fit content */
    margin-bottom: 20px; /* Add space between cards */
  }

  .user-details {
    flex-direction: column; /* Stack avatar and name */
    align-items: center; /* Center elements */
    margin-top: 0; /* Remove negative margin */
  }

  .avatar {
    width: 50px;
    height: 50px; /* Smaller avatar for smaller screens */
  }

  .user-name {
    width: auto;
    text-align: center; /* Center align username */
    margin-top: 10px; /* Add spacing between avatar and name */
  }

  .referred-by {
    margin-left: 0;
    margin-top: 5px;
    text-align: center;
  }

  .action-buttons {
    flex-direction: column; /* Stack buttons */
    align-items: center;
    gap: 10px; /* Space between buttons */
  }

  .accept-button,
  .decline-button {
    width: 100%; /* Full-width buttons */
    max-width: 200px; /* Limit button size */
  }
}

@media (max-width: 480px) {
  .avatar {
    width: 40px;
    height: 40px; /* Even smaller avatar */
  }

  .card1 {
    padding: 5px;
    font-size: 14px; /* Smaller text size */
  }

  .action-buttons {
    gap: 5px; /* Reduce spacing on small screens */
  }
}

//used with tailwind dont remove
.cardbody {
  transition: box-shadow 0.3s ease-in-out;
}
.cardbody:hover {
  box-shadow: 0px 0px 17.54px rgba(0, 0, 0, 0.1),
    /* Top shadow */ 0px 0px 17.54px rgba(0, 0, 0, 0.1),
    /* Left shadow */ 0px 0px 17.54px rgba(0, 0, 0, 0.1);
  /* Right shadow */
}
