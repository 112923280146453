.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .table-header {
    align-items: flex-start;
  }
}
.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .p-toolbar {
    flex-wrap: wrap;
  }
  .datatable-crud-demo .p-toolbar .p-button {
    margin-bottom: 0.25rem;
  }
  .datatable-crud-demo .table-header {
    flex-direction: column;
  }
  .datatable-crud-demo .table-header .p-input-icon-left,
  .datatable-crud-demo .table-header input {
    width: 100%;
  }
}

// .p-dialog-mask.p-component-overlay {
//   z-index: 0 !important;
// }
.btn-adjuster-footer-area-model {
  display: flex;
  float: right;
  width: max-content;
  .p-button {
    margin: 0 0.5rem 0 0;
    color: white !important;
  }
}

.del-con .p-button-text {
  .delete-btn {
    border: none;
    background-color: #ef4444 !important;
  }
}
.p-button {
  color: white !important;
}
.red-color-btn {
  background-color: #ef6464 !important;
  border: 1px solid #ef6464 !important;
}
.p-button-spacing {
  border-radius: 50%;
  height: 3rem;
  margin-left: 5px;
}
.toolbar-radius-remover {
  .p-toolbar {
    border-radius: 0px !important;
  }
}
