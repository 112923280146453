.grid-flex {
  flex: 0;
}
// .custom-button-width {
//     min-width: 15em !important;
//     max-width: fit-content;
//     flex-wrap: wrap;
//     // color: white !important;
//     background: #037eea !important;
//     border-color: #037eea !important;
//     text-align: left;
// }

// .customize-report-skip-button:hover {
//     border-color: #e4003b;
//     color: #e4003b;
// }

// .ant-btn-primary[disabled],
// .ant-btn-primary[disabled]:hover,
// .ant-btn-primary[disabled]:focus,
// .ant-btn-primary[disabled]:active {
//     background: #f5f5f5 !important;
//     color: rgba(0, 0, 0, 0.25) !important;
// }

// .custom-button-width:hover {
//     color: white;
// }

// // .ant-page-header {
// //   background-color: #f2f2f2;
// // }
// // .self-service-layout {
// //   background-color: none !important;
// // }
// .ant-page-header {}

// .ant-page-header-heading {
//     display: flex;
//     align-content: flex-end;
//     align-items: flex-end;
//     flex-direction: column;
// }

// .ant-page-header-heading-extra {
//     margin: 15px 3px;
// }

// .ant-steps-item-icon {
//     line-height: 29px;
// }

// .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
//     background: #ffff;
// }

// .ant-steps-icon {
//     color: #000000 !important;
// }

// .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
//     color: #037eea;
// }

// .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
//     color: #037eea;
// }

// .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
//     background-color: #000000 !important;
//     color: #037eea;
// }

// .ant-steps-item-tail::after {
//     background-color: #e2cece !important;
// }

// .custom-btn-color {
//     background-color: #e4003b;
//     background: #e4003b;
// }

// .ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
//     height: 65px;
// }

// .customize-report-skip-btn:hover {
//     border-color: #e4003b;
//     color: #e4003b;
// }

// .ant-input:hover {
//     border-color: #e4003b;
// }

// .ant-input:focus {
//     border-color: #e4003b;
//     -webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
//     box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
// }

// .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//     border-color: #e4003b;
// }

// .ant-input-affix-wrapper-focused {
//     border-color: #e4003b;
//     -webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
//     box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
// }

// .ant-input-affix-wrapper:hover {
//     border-color: #e4003b;
// }

// .ant-picker:hover {
//     border-color: #e4003b;
// }

// .ant-picker:focus {
//     border-color: e4003b;
//     -webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
//     box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
// }

// .ant-picker-focused {
//     border-color: e4003b;
//     -webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
//     box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
// }

// .custom-form-design {
//     width: 100%;
// }

// .custom-form-design .ant-form-item {
//     margin: 10px !important;
// }

// .ant-space-item .ant-form-item {
//     margin: 7px 8px !important;
// }

// .custom-scrollable-div {
//     overflow: auto;
//     max-height: 7rem;
//     min-height: auto;
//     margin-bottom: 1rem;
// }

// /* .custom-scrollable-div::-webkit-scrollbar {
//   width: 2px;
// } */

// /* ::-webkit-scrollbar {
//   width: 5px;
// } */

// // .custom-button-color .ant-modal-body .ant-btn-primary {
// //   background: #04c8eb !important;
// //   border-color: #04c8eb !important;
// // }
// .ant-btn-primary {
//     border: none;
// }

// .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
//     border-color: #037eea !important;
// }

// .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
//     font-variant: tabular-nums;
//     font-size: 14px !important;
// }

// .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
//     font-variant: tabular-nums;
//     font-size: 14px !important;
// }

// .ant-steps-item-finish .ant-steps-item-icon {
//     border-color: #037eea !important;
// }

// .steps-action {
//     display: flex;
//     justify-content: center;
//     .ant-btn-primary {
//         background-color: #037eea;
//         border: none;
//     }
// }

// .p-fluid .p-button-icon-only {
//     background-color: #037eea;
//     border: none;
// }

// // .ant-btn-default :hover{
// //     border-color: #d9d9d9 !important;
// // }
// .drag-adjuster {
//     .ant-upload-drag {
//         // margin: 0px 100px;
//         // margin-right: 10vw !importantx;
//         border: none;
//     }
//     .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
//         border: none;
//     }
//     .anticon {
//         color: #037eea !important;
//     }
// }

// .steps-content {
//     background-color: white;
//     border-radius: 15px;
//     .ant-upload-picture-card-wrapper {
//         display: flex;
//         justify-content: center;
//         margin-top: 30px;
//         margin-bottom: 10px;
//     }
//     //
// }

// .container-for-downloadBtn {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//      :hover {
//         color: white !important;
//         border-color: #04c8eb !important;
//         background: #04c8eb !important;
//     }
// }

// .download-btn {
//     // max-width: 12vw;
//     margin-top: 1vw;
//     margin-bottom: 5vh;
//     height: 7vh;
//     width: 13vw !important;
//     background-color: #037eea;
//     font-size: 17px;
//     font-weight: 700;
//     padding: 10px;
//     border-radius: 15px;
//     color: white;
//      :hover {
//         color: white !important;
//         border-color: #037eea !important;
//         background: #037eea !important;
//     }
// }

// .container-for-downloadBtn {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//      :hover {
//         color: white !important;
//         border-color: #037eea !important;
//         background: #037eea !important;
//     }
// }

// .span-for-download {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 6vw;
//     font-weight: 700;
//     font-size: 18px;
// }

// .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
//     background-color: #ffebbc;
// }

// .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
//     color: #037eea !important;
// }

// .product-badge {
//     border-radius: 2px;
//     padding: .25em .5rem;
//     text-transform: uppercase;
//     font-weight: 700;
//     font-size: 12px;
//     letter-spacing: .3px;
// }

// .product-badge.status-notAvailable {
//     background: #FFCDD2;
//     color: #C63737;
// }

// .product-badge.status-available {
//     background: #C8E6C9;
//     color: #256029;
// }

// .ant-picker-small {
//     width: 150px;
// }

// // .container-for-downloadBtn .download-btn
// // .p-fluid .p-input-icon-left, .p-fluid .p-input-icon-right {
// //   /* display: block; */
// //   width: 0% !important;
// // }
// //////////////////////////////////////////////////////////////////////////////////////
// ///
// .container{
//     display: flex;
//     justify-content: center;
//     .tag{
//         font-size: 3.5rem;
//         color: black;
//         font-weight: 600;
//     }
// }

.layout-dashborad {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  margin: 0.5%;
  // max-width: 160px;
  width: 32%;
  min-width: 24%;
  overflow: auto;
    // box-shadow: 5px 10px #888888;
    border: 0.1em solid #888888;
}

.dashboradcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vertical-line {
    
  display: inline-block;
  border-left: 2px solid #ccc;
  margin: 0 10px;
  height: 50px;
}

.main {
  cursor: pointer;
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 48%;
}
// .main1{
//     display: flex;
//     flex-direction: row;
//     margin-left: 30%;
// }
.bolder {
  font-weight: 500 !important;
  color: black !important;
}

.submain {
  cursor: pointer;
  // display: flex;
  width: 48%;

  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.cycletile {
  cursor: pointer;
}

.layout-dashborad-donation-graph {
    border-radius: 15px;
    margin-top: 2%;
    width: 33%;
    // box-shadow: 5px 10px #888888;
    border: 0.1em solid #888888;
}

.layout-dashborad-deservant-graph {
    border-radius: 15px;
    margin-top: 2%;
    margin-left: 10px;
    width: 33%;
    // box-shadow: 5px 10px #888888;
    border: 0.1em solid #888888;
}

.layout-main-height{
    background-color: white;
    flex: 1 1 auto;
    border-radius: 15px;
    padding: 15px;
    margin: 0.5%;
    // height: 49vh;
    width: 10%;
}
.sep-chart{
    display: flex;
    // justify-content: space-between;
}
.margin-chart-top{
    margin-top: 2%;
    // box-shadow: 5px 10px #888888;
    border: 0.1em solid #888888;
    border-radius: 15px;
    width: 33%;
    margin-left: 11px;
}
.heading-graphics{
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0.25rem;

}
.aligner-con-header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.orangerColor{

}
.greenColor{

}