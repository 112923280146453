.del-con{
    .delete-btn{
        border: none;
        background-color: #EF4444 !important;
    }
    
}
.p-button{
   
    color: white !important;
 }
 .submit-btn{
    
    float:right;
     width: 7rem !important;
     margin-right: 50px;
}
.cancel-btn{
   
   float:right;
    width: 7rem !important;
    margin-right: 5px;
    background-color: #EF4444 !important;
   }
.tab-header-adjuster-distributor{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-input-icon-left{
    margin-right: 1rem;
}

// .p-paginator{
//         display: flex;
//         align-items: end;
//         justify-content: center;
//         flex-wrap: wrap;
//         height:20rem;

// } 

// .p-datatable-wrapper{
//     // align-content: end;
//     // align-items: end;
//     // height: 18rem;
//     // align-self: end;
// }
// .p-paginator p-component p-paginator-bottom

// .card {
//     background-color: var(--surface-card);
//     padding: 1.5rem;
//     margin-bottom: 1rem;
//     border-radius: 12px;
//     height: 43.9rem;
//     box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08) !important;


// }
.left-margin-giver{
    margin-left: 5px;
}
.edit-delete-btn{
    display: flex;
    flex-direction: row;
    margin-left: -15px;
}

.red-color-btn{
    background-color: #ef6464 !important;
    border: 1px solid #ef6464 !important;
}
.p-button-spacing{
    border-radius: 50%;
    height: 3rem;
    margin-left: 5px;
}

.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}
// .p-multiselect-panel p-component p-connected-overlay-enter-done{
//     left: 480.986px;
// }
// .asd{
// .p-multiselect-panel{
//     z-index: 1002;
//     padding-right: 0px;
//     min-width: 6px;
//     transform-origin: center top;
//     top: 279.75px;
//     left: 491.016px !important;
//     width: 20rem !important;
// }
// }