.table-adjuster{
    .p-datatable .p-datatable-header {
        display: flex;
        justify-content: flex-end;
    }
}

.tab-adjuster{
    display: flex;
    align-items: center;
    width: 83vw;
    justify-content: space-between;
    .p-input-icon-left{
        display: flex;
    }
}
.table-sel{
    .p-datatable-header{
        display: flex;
    
    }
}
