.donorhover:hover {
    cursor: pointer;   
     background-color: lightblue;
    // text-shadow: 2px 2px 5px black;
// box-shadow: 10px 10px 5px 12px lightblue;

}

.tab-adjuster-total-donations{
    display: flex;
    justify-content: space-between;
}
