.mytab {
  min-height: 73vh;
  max-height: 73vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.download-icon {
  font-size: 20px;
  transition: color 0.3s;
}

.download-icon:hover {
  color: #1890ff;
}

.delete-icon {
  font-size: 20px;
  transition: color 0.3s;
}

.delete-icon:hover {
  color: #da0e0e;
}

.custom-modal .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.1);
}
