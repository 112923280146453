.main-body {
  margin: 0px 230px 0px 230px;
  padding-bottom: 50px;
}

.intro {
  text-align: center;
}

p {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #000000;
  margin: 0px 0px 20px 0px;
}

.end {
  border-bottom: #000000;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 50px;
}

.container {
  text-align: center;
}

@media only screen and (max-width: 1360px) {
  .main-body {
    margin: 0px 20px 0px 20px;
  }

  p {
    font-size: 16px;
  }

  .end {
    margin: 20px;
  }

  .container {
    text-align: justify;
  }

  .intro {
    text-align: justify;
  }
}
