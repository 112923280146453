body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf1f5 0% 0% no-repeat padding-box !important;
  // overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.p-button-success {
  background-color: #00b1ff !important;
  width: 7rem !important;
}
.p-button {
  width: 7rem;
  display: flex;
  justify-content: center;
  background-color: #00b1ff !important;
}
.mytab {
  min-height: 69vh;
  max-height: 69vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p-tabview .p-tabview-panels {
  padding: 1.25rem 1.25rem 2.5rem 1.25rem !important;
}
.newtab {
  min-height: 81vh;
  .p-paginator {
    position: absolute;
    bottom: 0px;
    left: 25%;
  }
}
.donationtab {
  min-height: 38vh;

  .p-paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-end;
    flex-direction: row;
  }
}
.layout-main-table {
  height: 81vh;
}

*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar:horizontal {
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  background: #1677ff 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
